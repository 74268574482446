
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux_store/store';

import App from 'pages/App';

document.addEventListener('DOMContentLoaded', () => {
  const div = document.createElement('div');
  div.className = 'master-app-container';
  document.body.appendChild(div);
  const root = createRoot(div);


  const originalError = console.error;

  console.error = (...args) => {
    if (
      typeof args[0] === 'string' &&
      args[0].includes('Support for defaultProps will be removed from function components')
    ) {
      return; // Skip this specific warning
    }
    originalError(...args); // Log other warnings
  };

  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  );
});
