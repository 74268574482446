import _ from 'lodash';

export const sanitizePlaceOrdersBeforeMarginCalculation = (orders) => {
  return _.map(orders, (order) => {
    const { prctyp, price, trigPrice } = order;
    return {
      ...order,
      price: prctyp === 'MKT' || prctyp === 'SL-M' ? 0 : price,
      trigPrice: prctyp === 'MKT' || prctyp === 'LMT' ? 0 : trigPrice,
    };
  });
};
